<template>
  <b-tag
    class="task-status"
    :type="taskStatusType">
    {{ taskStatus }}
  </b-tag>
</template>

<script>
import { TASK_STATES } from '@js/constants'
import { formatTimeFromDate } from '@js/utils'

export default {
  name: 'task-status',
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    taskFailed() {
      return this.task.state === TASK_STATES.FAILED
    },
    taskCompleted() {
      return this.task.state === TASK_STATES.COMPLETED
    },
    taskStatus() {
      if (this.task.isCancelled) {
        return this.$t('tasks.status.cancelled')
      }
      if (!this.taskFailed && !this.taskCompleted) {
        if (this.task.state === TASK_STATES.IN_DELIVERY) {
          if (this.task.lateBy) {
            return this.$tc('tasks.status.late', this.task.lateBy, { lateBy: this.task.lateBy })
          }
          if (this.task.eta !== null) {
            return this.$tc('tasks.status.eta', this.task.eta, { eta: this.task.eta })
          }
        }
      } else if (this.taskCompleted) {
        return this.$t('tasks.status.completedAt', { time: formatTimeFromDate(this.task.completedAt) })
      }
      return this.$t(`tasks.status.${this.task.state}`)
    },
    taskStatusType() {
      if (this.task.isCancelled || this.taskFailed || this.task.lateBy) {
        return 'is-danger'
      }
      return 'is-dark'
    }
  }
}
</script>
